
import { defineComponent, ref } from 'vue'
import NavBar from '@/components/NavBar.vue'
import Game from '@/api/Game'
import MSocket from '@/api/socket'

export default defineComponent({
  name: 'Game',
  components: {
    NavBar
  },
  setup () {
    const chatbox = ref<HTMLDivElement>()

    return {
      chatbox
    }
  },
  data () {
    return {
      chatboxheigth: '',
      heigthupdateInterval: false as number | false,
      focusedOnChat: false,
      focusedinterval: undefined as number | undefined,

      messages: [] as {username: string, text: string; userid: string; type?: string}[],
      send: '',
      users: this.$game.users,
      streak: this.$game.streak,
      streakUser: this.$game.streakUser,
      question: this.$game.question,
      wordcount: this.$game.wordcount,
      waiting: this.$game.waiting,
      success: false as {
        userid: string;
        word: string;
        time: number;
        username: string;
      } | false,
      won: false as {
        id: string;
        points: number;
        username: string;
      }[] | false
    }
  },
  watch: {
    windowHeight: {
      handler () {
        this.calcHeight()
      },
      deep: true
    },
    windowWidth: {
      handler () {
        this.calcHeight()
      },
      deep: true
    },
    waiting () {
      if (!this.waiting) {
        this.success = false
        this.won = false
      }
    }
  },
  mounted () {
    this.heigthupdateInterval = setInterval(this.calcHeight, 10)

    Game.onChat.addEventListener((data) => {
      this.messages.push(data)
      this.scrollDown()
    })

    Game.onSuccess.addEventListener((data) => {
      this.success = data
    })

    Game.onWon.addEventListener((data) => {
      this.won = data
    })

    Game.onJoinMessage.addEventListener(data => {
      this.messages.push({
        username: data.username,
        text: '',
        userid: data.id,
        type: 'join'
      })
      this.scrollDown()
    })

    Game.onLeaveMessage.addEventListener(data => {
      this.messages.push({
        username: data.username,
        text: '',
        userid: data.id,
        type: 'leave'
      })
      this.scrollDown()
    })

    this.focusedinterval = setInterval(this.updateFocusedOnChat, 100)
  },
  beforeUnmount () {
    if (this.heigthupdateInterval) {
      clearInterval(this.heigthupdateInterval)
    }
    if (this.focusedinterval) {
      clearInterval(this.focusedinterval)
    }
  },
  methods: {
    calcHeight () {
      if (this.viewport <= 2) {
        // eslint-disable-next-line
        const v = this.windowHeight - (this.chatbox?.getBoundingClientRect().top || 0) - (this.focusedOnChat ? 70 : 123)
        this.chatboxheigth = v + 'px'
      } else {
        this.chatboxheigth = 'calc( 100vh - 180px )'
      }
    },
    sendMsg () {
      if (this.send) {
        MSocket.socket.emit('game.chat', this.send)
        this.send = ''
      }
    },
    scrollDown () {
      if (this.chatbox) {
        if (this.chatbox.scrollTop + this.chatbox.clientHeight === this.chatbox.scrollHeight) {
          setTimeout(() => {
            if (this.chatbox) {
              this.chatbox.scrollTop = this.chatbox.scrollHeight
            }
          }, 10)
        }
      }
    },
    updateFocusedOnChat () {
      this.focusedOnChat = document.activeElement === document.getElementById('chatinput')
    }
  }
})
